import { ForceNodes, md, Node } from "djedi-react";
import cookies from "js-cookie";
import PropTypes from "prop-types";
import React from "react";
import { Field, Form, FormSpy } from "react-final-form";

import Button from "#components/Button";
import LabeledField from "#components/LabeledField";
import Text from "#components/Text";
import { useBooking } from "#containers/Booking/BookingContext";
import { VOUCHER_COOKIE_NAME } from "#utils/booking";
import { trimOnBlur } from "#utils/forms";

import styles from "./AddVoucher.module.css";

export const VOUCHER_FIELD_NAME = "code";

const TEXT = (
  <Node uri="AddVoucher/text.md">{md`
    Pendlarkort, värdebevis, företagsavtal eller kampanjkod
  `}</Node>
);

const PLACEHOLDER = <Node uri="AddVoucher/placeholder">Ange kod…</Node>;

const SAVE = <Node uri="AddVoucher/save">Spara</Node>;

export const VOUCHER_FIELD_ERRORS = {
  not_found: (
    <Node uri="AddVoucher/error/not_found">
      Koden ser inte ut att vara rätt! Dubbelkolla att du fyllt i rätt kod.
    </Node>
  ),
  max_used: (
    <Node uri="AddVoucher/error/max_used">
      Denna kod kan inte användas fler gånger.
    </Node>
  ),
  expired: <Node uri="AddVoucher/error/expired">Denna kod har gått ut.</Node>,
  consumed: (
    <Node uri="AddVoucher/error/consumed">Denna kod är redan förbrukad.</Node>
  ),
  too_many_requests: (
    <Node uri="AddVoucher/error/too_many_requests">
      Du verkar ha testat många koder på kort tid. Vänta en stund och försök
      sedan igen.
    </Node>
  ),
  limited: (
    <Node uri="AddVoucher/error/limited">
      Denna kod är inte giltig med ditt nuvarande val.
    </Node>
  ),
  personal: (
    <Node uri="AddVoucher/error/personal">Denna kod är personlig.</Node>
  ),
  other: <Node uri="AddVoucher/error/other">Misslyckades att spara kod.</Node>,
};

const AddVoucher = (props) => {
  const { loading } = useBooking();
  const { onSubmit = async () => undefined, onClose, disabled } = props;

  return (
    <>
      <div className={styles.root}>
        <div className={styles.header}>
          <Text>{TEXT}</Text>
          <button
            className={styles.close}
            type="button"
            onClick={onClose}
          ></button>
        </div>

        <Form
          initialValues={{
            [VOUCHER_FIELD_NAME]: cookies.get(VOUCHER_COOKIE_NAME) || "",
          }}
          onSubmit={onSubmit}
        >
          {({ handleSubmit }) => (
            <form className={styles.form} onSubmit={handleSubmit}>
              <Field
                disabled={loading}
                className={styles.field}
                // validate={required(REQUIRED)}
                placeholder={PLACEHOLDER}
                name="code"
                {...trimOnBlur}
                component={LabeledField}
              />
              <FormSpy subscription={{ values: true }}>
                {({ values }) => (
                  <Button
                    disabled={
                      Boolean(disabled || loading) || values.code === ""
                    }
                    className={styles.button}
                    color="secondary"
                    type="submit"
                  >
                    {SAVE}
                  </Button>
                )}
              </FormSpy>
            </form>
          )}
        </Form>

        <ForceNodes>
          {TEXT}
          {PLACEHOLDER}
          {SAVE}
          {VOUCHER_FIELD_ERRORS}
        </ForceNodes>
      </div>
    </>
  );
};

AddVoucher.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
};

AddVoucher.defaultProps = {
  onSubmit: async () => undefined,
  disabled: false,
};

export default AddVoucher;
