/* global require */
import "../main/browser";
import "../main/css/global.css";
import "intersection-observer";

import { djedi, NodeContext } from "djedi-react";
import {
  defaultLocale,
  LANGUAGE_COOKIE,
  LANGUAGE_COOKIE_ADMIN,
  locales,
} from "i18n";
import unfetch from "isomorphic-unfetch";
import cookies from "js-cookie";
import App from "next/app";
import getConfig from "next/config";
import Head from "next/head";
import Router from "next/router";
import NProgress from "nprogress";
import React from "react";

import AppContext from "#components/AppContext";
import LoginModal, { LoginModalContext } from "#components/LoginModal";
import { getBookingSessionCookieName } from "#containers/Booking/helpers";
import appleTouchIcon from "#images/apple-touch-icon.png";
import favicon from "#images/favicon.ico";
import favicon32x32OpaqueIco from "#images/favicon-32x32-opaque.ico";
import favicon32x32OpaquePng from "#images/favicon-32x32-opaque.png";
import favicon32x32TransparentIco from "#images/favicon-32x32-transparent.ico";
import favicon32x32TransparentPng from "#images/favicon-32x32-transparent.png";
import { deleteNil, HttpError } from "#utils";
import { deleteAllBookingSessionData } from "#utils/booking";
import { extractUrlData } from "#utils/req";
import { analytics } from "#utils/tracking";

import Api, { API_AUTH, API_URL, patchResponse } from "../api";
import SuisseIntlMedium from "../public/static/fonts/SuisseIntl/SuisseIntl-Medium-WebS.woff2";
import SuisseIntlRegular from "../public/static/fonts/SuisseIntl/SuisseIntl-Regular-WebS.woff2";
import SuisseIntlSemiBold from "../public/static/fonts/SuisseIntl/SuisseIntl-SemiBold-WebS.woff2";
import SuisseIntlMonoBold from "../public/static/fonts/SuisseIntl/SuisseIntlMono-Bold-WebS.woff2";
import { captureException } from "../sentry";

require("core-js/es/object/values");
require("core-js/es/string/repeat");
require("core-js/es/array/flat");

const { publicRuntimeConfig } = getConfig();
const { APP_HOST, DOCUMENT_DOMAIN } = publicRuntimeConfig;

const OTHER_LOCALES = locales.filter((language) => language !== defaultLocale);

async function getUserCredentials(options) {
  if (self.mtrxAppMode != null) {
    const credentials = await self.mtrxAppMode.requestUserCredentials();
    return {
      email: credentials.username,
      password: credentials.password,
    };
  }

  try {
    const credentials = await window.navigator.credentials.get({
      password: true,
      mediation: options?.mediation,
    });
    return {
      email: credentials.id,
      password: credentials.password,
    };
  } catch {
    return null;
  }
}

/* eslint-disable */
// TODO (jimmy): remove this polyfill once we have updated core-js and next
if (!Array.prototype.flat) {
  Object.defineProperty(Array.prototype, "flat", {
    configurable: true,
    value: function flat() {
      var depth = isNaN(arguments[0]) ? 1 : Number(arguments[0]);

      return depth
        ? Array.prototype.reduce.call(
          this,
          function (acc, cur) {
            if (Array.isArray(cur)) {
              acc.push.apply(acc, flat.call(cur, depth - 1));
            } else {
              acc.push(cur);
            }

            return acc;
          },
          []
        )
        : Array.prototype.slice.call(this);
    },
    writable: true,
  });
}
/* eslint-enable */

const isClient = typeof document !== "undefined";

if (isClient) {
  document.globalHideImportantMessage = false;
}

if (typeof window !== "undefined") {
  // TODO: Muting error, fix as soon as zeit/next.js/issues/7915 resolved
  const originalError = console.error;

  console.error = (...args) => {
    if (/Warning.*Function components cannot be given refs/.test(args[0])) {
      return;
    }
    originalError.call(console, ...args);
  };

  // Polyfill `Element.prototype.closest` for IE11.
  require("element-closest/browser");

  const smoothscroller = require("smoothscroll-polyfill");
  smoothscroller.polyfill();

  require("focus-visible");

  // Allow this site to reach into the Freshdesk `<iframe>`.
  try {
    document.domain = DOCUMENT_DOMAIN;
  } catch (error) {
    console.error(
      `Failed to set document.domain to "${DOCUMENT_DOMAIN}"`,
      error
    );
  }
}

Router.events.on("routeChangeStart", () => {
  NProgress.start();
});

Router.events.on("routeChangeComplete", (url) => {
  NProgress.done();

  if (typeof window !== "undefined") {
    analytics("set", "page", url);
    analytics("set", "title", document.title);
    analytics("send", "pageview");

    if (window.location.host === APP_HOST) {
      const message = JSON.stringify({ type: "URL_CHANGE", message: url });
      if (window.ReactNativeWebView != null) {
        window.ReactNativeWebView.postMessage(message);
      } else {
        // eslint-disable-next-line
        console.log("ReactNativeWebView missing. Message:", message);
      }
    }
  }
});

Router.events.on("routeChangeError", () => {
  NProgress.done();
});

djedi.options.languages = {
  default: defaultLocale,
  additional: OTHER_LOCALES,
};

// This uses `API_URL` even in app mode (instead of `API_URL_APP`) because it’s
// easier and doesn’t matter.
djedi.options.baseUrl = `${API_URL}/cms/api`;

djedi.options.fetch = (url, options = {}) =>
  unfetch(url, {
    ...options,
    headers: deleteNil({
      ...options.headers,
      Authorization: API_AUTH,
    }),
  });

djedi.options.defaultRender = (state, { language }) => {
  switch (state.type) {
    case "loading":
      switch (language) {
        case "en":
          return "Loading…";
        case "sv":
        default:
          return "Laddar…";
      }
    case "error": {
      const status =
        state.error.response != null ? state.error.response.status : -1;
      switch (language) {
        case "en":
          return `Failed to fetch content 😞 (${status})`;
        case "sv":
        default:
          return `Innehållet gick inte att hämta 😞 (${status})`;
      }
    }
    case "success":
      return state.content;
    default:
      return null;
  }
};

patchResponse();

// Global stuff that needs to be available in `getInitialProps` is stored in
// here. In the browser, `Storage` should be instantiated only once, so state
// can be kept between pages. On the server, we need a new instance for every
// page render, unqiue to the current user.
class Storage {
  constructor({ req = undefined, res = undefined } = {}) {
    this.api = new Api({ req, res });
  }

  toJSON() {
    return {
      isSerialized: true,
      ssrRequests: this.api.ssrRequests,
    };
  }
}

const globalStorage = typeof window !== "undefined" ? new Storage() : undefined;

export default class MyApp extends App {
  static async getInitialProps({ Component, ctx: passedCtx }) {
    const { req, res } = passedCtx;

    // In the browser, re-use the global storage. On the server, create a new
    // one.
    const storage =
      globalStorage != null ? globalStorage : new Storage({ req, res });
    const { api } = storage;

    // `api` and `user` are added to `ctx`, so that other pages’
    // `getInitialProps` can use them.
    const ctx = enhanceCtx(passedCtx, storage);

    const { language } = ctx;

    // eslint-disable-next-line
    const userAgent = req ? req.headers["user-agent"] : navigator.userAgent;
    const { baseUrl, appMode } = extractUrlData(req);

    // The API requests use a different API base URL in app mode.
    api.appMode = appMode;

    const baseProps = {
      baseUrl,
      appMode,
      language,
      userAgent,
      // Pass the storage instance down to render. (Remember, on the server the
      // storage instance is constructed here in `getInitialProps`.) `Storage`
      // has a `toJSON` that returns only the parts of it that we want Next.js
      // to serialize on the server and send to the browser.
      storage,
    };

    // Do as little as possible when rendering the error page, in case something
    // in this `getInitialProps` function fails. We don't want to error on the
    // error page, so to speak. Also, do as little as possible on admin pages.
    if (Component.bareBones) {
      return {
        ...baseProps,
        pageProps:
          Component.getInitialProps != null
            ? await Component.getInitialProps(ctx)
            : {},
      };
    }

    if (!locales.includes(language)) {
      throw new HttpError({
        status: 404,
        message: `Unknown language: ${language}`,
      });
    }

    const [pageProps] = await Promise.all([
      Component.getInitialProps != null ? Component.getInitialProps(ctx) : {},
      Component.skipDjediPrefetch
        ? undefined
        : djedi.prefetch({ language }).catch((error) => {
            if (error.response == null) {
              error.noResponse = true;
            }
            throw error;
          }),
    ]).catch((error) => {
      captureException(error, ctx);

      throw error;
    });

    const nodes = djedi.track();

    return { ...baseProps, pageProps, nodes };
  }

  constructor(props) {
    super(props);
    const { Component, nodes, storage } = props;

    if (!Component.bareBones) {
      if (nodes != null) {
        djedi.addNodes(nodes);
      }
      djedi.injectAdmin();
    }

    if (globalStorage != null && storage.isSerialized) {
      Object.assign(globalStorage, storage.dataToRestore);
    }

    this.state = {
      // This object users getters in case props change.
      app: Object.assign(
        toGetters({
          baseUrl: () => this.props.baseUrl,
          appMode: () => this.props.appMode,
          api: () => this.getStorage().api,
          language: () => this.props.language,
          userAgent: () => this.props.userAgent,
        }),
        {
          login: this.login,
          logout: this.logout,
          setLanguageCookie: this.setLanguageCookie,
        }
      ),
      loginModal: {
        isOpen: false,
        open: async () => {
          const credentials = await getUserCredentials();
          if (credentials == null) {
            this.setState((state) => ({
              loginModal: { ...state.loginModal, isOpen: true },
            }));
            return;
          }
          await this.login(credentials);
          await Router.replace(Router.router.asPath);
        },
        close: () => {
          this.setState((state) => ({
            loginModal: { ...state.loginModal, isOpen: false },
          }));
        },
      },
      userLoading: true,
    };
  }

  async componentDidMount() {
    const { api } = this.state.app;

    this.setLanguageCookie();
    const { ssrRequests } = this.props.storage;
    if (ssrRequests != null && ssrRequests.length > 0) {
      // eslint-disable-next-line
      console.log("SSR requests:", ssrRequests);
    }

    // Allow using `_api` in the console for playing around with requests during development.
    if (DEBUG) {
      Object.defineProperty(window, "_api", {
        get: () => this.state.app.api,
        configurable: true,
      });
    }

    try {
      const { data: user } = await api.getUser();
      this.setState({ user, userLoading: false });
    } catch {
      // Not empty
      this.setState({ userLoading: false });
    }
  }

  setLanguageCookie = (language = this.props.language) => {
    cookies.set(LANGUAGE_COOKIE.name, language, {
      expires: LANGUAGE_COOKIE.expires,
      domain: DOCUMENT_DOMAIN,
    });

    cookies.set(LANGUAGE_COOKIE_ADMIN.name, language, {
      expires: LANGUAGE_COOKIE_ADMIN.expires,
      domain: DOCUMENT_DOMAIN,
    });
  };

  componentDidCatch(error, errorInfo) {
    captureException(error, { errorInfo });
    super.componentDidCatch(error, errorInfo);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.language !== this.props.language) {
      document.documentElement.setAttribute("lang", this.props.language);
    }

    const { bareBones: prevBareBones = false } = prevProps.Component;
    const { bareBones = false } = this.props.Component;
    if (prevBareBones && !bareBones) {
      djedi.injectAdmin();
    }
  }

  getStorage() {
    return globalStorage != null ? globalStorage : this.props.storage;
  }

  login = async ({ email, password }) => {
    const { api } = this.state.app;
    const { data: user } = await api.login({ email, password });
    if (self.mtrxAppMode != null) {
      await self.mtrxAppMode.setUserCredentials({
        username: email,
        password: password,
      });
    }

    // Set userID for google analytics
    cookies.set("userId", user.id);
    this.setState({ user });
    djedi.injectAdmin();
  };

  logout = async () => {
    const { api } = this.state.app;
    if (self.mtrxAppMode != null) {
      await self.mtrxAppMode.setUserCredentials(null);
    }
    await api.logout();
    this.setState({ user: null });
    api.clearCache();
    cookies.remove("userId");
    deleteAllBookingSessionData();
    djedi.removeAdmin();
  };

  render() {
    const { Component, pageProps, language, router } = this.props;
    const { app, loginModal, user, userLoading } = this.state;
    const isAdminPage = router.asPath.startsWith("/_/");

    (async () => {
      // Display the ATT prompt if the user hasn't already made a choice.
      if (self.mtrxAppMode != null) {
        await self.mtrxAppMode.requestTrackingPermission();
      }
    })().catch(() => ({}));

    (async () => {
      if (isAdminPage || user) {
        return;
      }
      // If we're running in app mode, it makes sense to auto log in,
      // but for desktop it may make more sense to force the click to
      // log in since otherwise we can't log out. :thinking:
      if (self.mtrxAppMode != null || this.props.appMode) {
        const cred = await getUserCredentials({
          mediation: "silent",
        });
        if (cred != null) {
          await this.login(cred);
          await Router.replace(Router.router.asPath);
        }
      }
    })().catch(() => ({}));

    const replaceLang = (newLang) => {
      if (newLang === defaultLocale) {
        return router.asPath;
      }

      return `/${newLang}${router.asPath}`;
    };

    // Redirect to vr
    if (
      typeof window !== "undefined" &&
      window.location.hostname === "mtrx.travel"
    ) {
      if (
        self.mtrxAppMode == null &&
        !this.props.appMode &&
        !cookies.get(getBookingSessionCookieName())
      ) {
        const url = new URL(window.location.href);
        url.hostname = "vrresa.se";
        url.protocol = "https";
        url.port = "";
        const redirect = url.href;
        window.location.replace(redirect);

        return (
          <>
            <Head>
              <meta httpEquiv="refresh" content={`3;url=${redirect}`} />
            </Head>
            <a href={`${redirect}`}>
              Redirecting to vrresa in 3 seconds, click here if you are not
              redirected.
            </a>
          </>
        );
      }
      console.warn(
        "Redirect skipped due to active booking session or the user using the app."
      );
    }

    return (
      <>
        <Head>
          {/* This cannot be placed in _document.js, because that file is only
          compiled for the server build and no static files are emitted there. */}
          <link rel="icon" href={favicon} />
          <link rel="icon" href={favicon32x32TransparentIco} />
          <link rel="icon" type="image/png" href={favicon32x32TransparentPng} />
          <link
            rel="icon"
            media="(prefers-color-scheme: light)"
            href={favicon32x32OpaqueIco}
          />
          <link
            rel="icon"
            media="(prefers-color-scheme: light)"
            type="image/png"
            href={favicon32x32OpaquePng}
          />
          <link rel="apple-touch-icon" href={appleTouchIcon} />

          <link
            rel="preconnect"
            href="https://fonts.gstatic.com"
            crossOrigin="anonymous"
          />

          {/* VR FONT START */}
          <link
            as="font"
            crossOrigin="anonymous"
            rel="preload"
            type="font/woff2"
            href={SuisseIntlRegular}
          />
          <link
            as="font"
            crossOrigin="anonymous"
            rel="preload"
            type="font/woff2"
            href={SuisseIntlMedium}
          />
          <link
            as="font"
            crossOrigin="anonymous"
            rel="preload"
            type="font/woff2"
            href={SuisseIntlSemiBold}
          />
          <link
            as="font"
            crossOrigin="anonymous"
            rel="preload"
            type="font/woff2"
            href={SuisseIntlMonoBold}
          />
          {/* VR FONT end */}
          {locales.map((otherLang) => (
            <link
              key={otherLang}
              rel="alternate"
              hrefLang={otherLang}
              href={replaceLang(otherLang)}
            />
          ))}
          <link
            rel="alternate"
            hrefLang="x-default"
            href={replaceLang(defaultLocale)}
          />
        </Head>

        <AppContext.Provider value={{ ...app, user, userLoading }}>
          <NodeContext.Provider value={language}>
            <LoginModalContext.Provider value={loginModal}>
              {pageProps !== false && <Component {...pageProps} />}
              <LoginModal />
            </LoginModalContext.Provider>
          </NodeContext.Provider>
        </AppContext.Provider>
      </>
    );
  }
}

/*
This function enhances the standard Next.js `ctx` (passed to `getInitialProps`)
object in a couple of ways.

By default, the value of given key in `ctx.query` is a string if a the query
parameter was given _once,_ but if it was given multiple times (`?foo=1&foo=2`)
the value is an array of all of those values. Most of the time one wants only a
single value. This function normalizes `ctx.query` so the values are always
strings (by favoring the _last_ given query parameter), and adds `ctx.queryList`
(where the values always are arrays) if you need all the given values.

`ctx.language` contains the current language used.

`ctx.api` contains an api client instance.

`ctx.user` contains a user object if logged in.
*/
function enhanceCtx(ctx, storage) {
  const { query, queryList } = Object.entries(ctx.query).reduce(
    (result, [key, value]) => {
      result.query[key] = Array.isArray(value)
        ? value[value.length - 1]
        : value;
      result.queryList[key] = [].concat(value);
      return result;
    },
    { query: {}, queryList: {} }
  );

  return {
    ...ctx,
    query,
    queryList,
    language: ctx.locale,
    api: storage.api,
  };
}

function toGetters(object) {
  return Object.entries(object).reduce(
    (result, [key, fn]) =>
      Object.defineProperty(result, key, {
        configurable: false,
        enumerable: true,
        get: fn,
      }),
    {}
  );
}
