// Global CSS variables have to be defined in this very file (rather than in
// main/css/globals.js) in order not to have to restart the dev server every
// time you change any of them.

// Grey colors are named after Star Wars characters because naming is hard.
// const greyLeia = "#fafafa";
const greyChewie = "#f2f2f2";
const greyYoda = "#e0e0e0";
const greyLuke = "#d0d0d0";
// const greyWookie = "#d8d8d8";
const greyHan = "#ccc";
const greyBen = "#b7b7b7";
const greyLando = "#808080";
const greyBoba = "#31373e";
const greyVader = "#111111";
const greyKylo = "#555555";
const greyR2 = "#F5F5F5";
const greyBabuFrik = "#828282";
const greyRey = "#E8E8E8";

// new "Colorful" colors

const detectOldVariables = "#FF00FF";

// Breakpoints, inspired by https://material-ui.com/layout/breakpoints/
const minWidths = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};
const fontFamily = [
  "SuisseIntl",
  // "-apple-system",
  // "BlinkMacSystemFont",
  // "Segoe UI",
  // "Roboto",
  // "Oxygen",
  // "Ubuntu",
  // "Cantarell",
  // "Fira Sans",
  // "Droid Sans",
  // "Helvetica",
  // "Helvetica Neue",
  // "Arial",
  // "sans-serif",
  // "Apple Color Emoji",
  // "Segoe UI Emoji",
  // "Segoe UI Symbol",
];

const globals = {
  // ======================== Colors ========================
  // These are the colors that VR use in their design system
  // However, these are not strictly the ones used for this repaint
  // I've added these as a fallback if we need more colors.
  // Also just nice to have maybe :)
  black: "#000000",
  white: "#FFFFFF",

  primary08: "#008751",
  primary10: "#00A149",
  primary20: "#00B451",
  primary30: "#E2FAE0",

  secondary10: "#1B50AF",
  secondary15: "#1C50B0",
  secondary20: "#0078D4",
  secondary23: "#058EF2",
  secondary25: "#00ACF5",
  secondary30: "#D5EEFA",

  grey10: "#333333",
  grey15: "#404040",
  grey17: "#6E7176",
  grey20: "#90959D",
  grey25: "#B0B4B8",
  grey30: "#D8D8D8",
  grey35: "#E5E5E5",
  grey40: "#F2F2F2",
  grey60: "#FAFAFA",

  offer10: "#8A15F7",
  offer20: "#8E4DFF",

  error10: "#CC3700",
  error12: "#E85430",
  error15: "#F97253",

  alert20: "#F9BA2B",
  alert25: "#FCD054",
  alert30: "#FDE674",

  textPrimary: "#333333",
  textSecondary: "#6E7176",
  textDisabled: "#90959D",

  // Old colors used by mtrx

  // Grey colors.
  "g-grey60": detectOldVariables,
  "g-greyLuke": greyLuke,
  "g-greyBabuFrik": greyBabuFrik,
  "g-greyRey": greyRey,
  "g-greyBen": greyBen,
  "g-greyHan": greyHan,
  "g-greyChewie": greyChewie,
  "g-greyWookie": detectOldVariables,
  "g-greyLando": greyLando,
  "g-greyYoda": greyYoda,
  "g-greyBoba": greyBoba,
  "g-greyVader": greyVader,
  "g-greyKylo": greyKylo,
  "g-greyR2": greyR2,

  // "Colorful" colors.
  "g-red": detectOldVariables,
  "g-wine": detectOldVariables,
  "g-yellow": detectOldVariables,
  "g-pink": detectOldVariables,
  "g-lightPink": detectOldVariables,

  // Extra for tiers
  "g-turquoise": detectOldVariables,

  // Common usage for some colors.
  "g-textPrimaryColor": detectOldVariables,
  "g-borderColor": "#F5F5F5",
  "g-borderColorDark": detectOldVariables,
  "g-pageBackgroundColor": "#FFFFFF",
  "g-linkColor": detectOldVariables,
  "g-linkHoverColor": detectOldVariables,

  // Font stuff.
  "g-fontSize": "16px",
  "g-lineHeight": "1.628571",
  "g-fontFamily": fontFamily.join(", "),
  "g-fontFamilyAlternate": ["Mtrx headline", ...fontFamily].join(", "),
  "g-fontFamilyMono": ["SuisseIntlMono", "monospace", ...fontFamily].join(", "),

  // The font weights used.
  // Only use "g-normal" when "g-default" is too thin and "g-bold" is too thick.
  "g-default": "400",
  "g-normal": "400",
  "g-semiBold": "500",
  "g-bold": "600",
  "g-black": "900",

  // All major `z-index` values.
  "g-z-index-bookingTimer": 7000,
  "g-z-index-stickyBooking": 8000,
  "g-z-index-messageBanner": 8500,
  "g-z-index-Nav": 9000,
  "g-z-index-Nav-wrapper": 8999,
  "g-z-index-Drawer": 9000,
  "g-z-index-Modal": 9500,
  "g-z-index-TopNotification": 10000,
  "g-z-index-Nprogress": 20000,

  // Measurements that need to be shared between several components. They are
  // named after the component that “owns” it (but other components can use it).
  "g-Button_base-height": "44px",
  "g-articleWidth": "768px",

  "g-MobileNavHeight": "60px",

  // Breakpoints.
  "g-minWidth_xs": minWidths.xs,
  "g-minWidth_sm": minWidths.sm,
  "g-minWidth_md": minWidths.md,
  "g-minWidth_lg": minWidths.lg,
  "g-minWidth_xl": minWidths.xl,

  // Usage example: `@media ($smDown) {}`.
  xsDown: maxWidth(minWidths.sm),
  smDown: maxWidth(minWidths.md),
  mdDown: maxWidth(minWidths.lg),
  lgDown: maxWidth(minWidths.xl),
  xsUp: minWidth(minWidths.sm),
  smUp: minWidth(minWidths.sm),
  mdUp: minWidth(minWidths.md),
  lgUp: minWidth(minWidths.lg),
  xlUp: minWidth(minWidths.xl),

  // Spacing
  "g-spaceLarge": "50px",
  "g-spaceMedium": "30px",
  "g-spaceSmall": "20px",
  "g-spaceTiny": "12px",

  // other

  "g-navHeight": "64px",
  "g-bookingStepsHeight": "48px",
  "g-bookingStepsHeightMobile": "30px",

  "g-boxShadow": "2px 2px 15px rgba(0, 0, 0, 0.15)",
};

module.exports = {
  plugins: {
    "postcss-simple-vars": { variables: globals },
    "postcss-nested": { preserveEmpty: true },
    "postcss-color-mod-function": {},
    "postcss-calc": { preserve: true },
    "postcss-preset-env": {
      preserve: true,
      stage: 3,
      features: {
        "media-query-ranges": true,
        "custom-properties": true,
        "gap-properties": true,
        "nesting-rules": true, // Enable nesting
      },
    },
  },
};

function maxWidth(width) {
  return `max-width: ${width - 1}px`;
}

function minWidth(width) {
  return `min-width: ${width}px`;
}
