export default function extrasFromInitialData(initialData, numPassengers) {
  return {
    stroller: initialData.stroller === "true",
    pet: initialData.pet === "true",
    wheelchairs:
      (numPassengers >= 1 && initialData.wheelchairs === "1") ||
      (numPassengers >= 2 && initialData.wheelchairs === "2")
        ? Number(initialData.wheelchairs)
        : 0,
    walkers:
      (numPassengers >= 1 && initialData.walkers === "1") ||
      (numPassengers >= 2 && initialData.walkers === "2")
        ? Number(initialData.walkers)
        : 0,
  };
}
